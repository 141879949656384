import {
    AppUser,
    fetchWithUserCredentials,
    getBackendURL,
    globalLocation,
    LocationScope,
    LocationScopeWithMetadata,
    useLogin,
} from "@ingka-livlig/frontend-lib";
import { useMemo } from "react";

const hostname = getBackendURL();

export interface LocationsAPI {
    getMetadata(location: LocationScope): Promise<LocationScopeWithMetadata | null>;
}

function locationsAPI(user: AppUser): LocationsAPI {
    return {
        getMetadata: async (location: LocationScope) => {
            switch (location.scope) {
                case "Global":
                    return globalLocation;
                case "Country": {
                    const result = await fetchWithUserCredentials(
                        user,
                        `${hostname}/api/v1/locations/Country/${location.countryCode}`,
                    );
                    if (result.status === 204) {
                        return null;
                    } else {
                        return await result.json();
                    }
                }
                case "SellingUnit": {
                    const result = await fetchWithUserCredentials(
                        user,
                        `${hostname}/api/v1/locations/SellingUnit/${location.sellingUnitCode}`,
                    );
                    if (result.status === 204) {
                        return null;
                    } else {
                        return await result.json();
                    }
                }
            }
        },
    };
}

export function useLocationsAPI() {
    const { user } = useLogin();
    return useMemo(() => locationsAPI(user), [user]);
}
