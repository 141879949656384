import {
    Center,
    extractSelectedLanguage,
    getBackendURL,
    LoadingIndicator,
    LocationScopeWithMetadata,
    Page,
    persistLanguageSetting,
    salesUrl,
    Segment,
    unlessSuccessful,
    useApiCall,
    useLogin,
} from "@ingka-livlig/frontend-lib";
import { Trans, useLingui } from "@lingui/react/macro";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAPI } from "./userAPI";
import { StringParam, useQueryParam } from "use-query-params";
import { useInterval } from "react-use";
import styles from "./RemoteLogin.module.css";
import { localeActivate } from "./i18n";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import Button from "@ingka/button";
import { useMetadataAPI } from "./metadataAPI";
import { useLocationsAPI } from "./locationsAPI";
import { LocationSelector } from "./SelectLocation";

export function RemoteLogin() {
    const { t } = useLingui();
    const { decodedToken } = useLogin();
    const api = useUserAPI();
    const remoteLoginRequest = useApiCall(api.remoteLoginCreate);

    const [hasExpired, setHasExpired] = useState(false);

    const lang = extractSelectedLanguage();
    useEffect(() => {
        // With this method we dynamically load the catalogs
        localeActivate(lang);
    }, []);

    useInterval(
        async () => {
            if (remoteLoginRequest.tag === "success") {
                const pollResponse = await api.remoteLoginPoll(remoteLoginRequest.value.sessionId);
                if (pollResponse !== undefined && pollResponse !== "expired") {
                    console.log(`Got response redirecting to '${pollResponse.redirectUrl}'`);
                    if (!decodedToken) {
                        console.error(
                            "There was no user set, which indicates that there was a problem setting the 'auth' cookie.",
                        );
                        //Perhaps handle this better?
                        setHasExpired(true);
                    } else {
                        persistLanguageSetting(pollResponse.locale);
                        localeActivate(pollResponse.locale);
                        window.location.assign(pollResponse.redirectUrl);
                    }
                } else if (pollResponse === "expired") {
                    setHasExpired(true);
                }
            }
        },
        hasExpired ? null : 1000,
    );

    function renderContent() {
        if (remoteLoginRequest.tag === "loading") {
            return <LoadingIndicator />;
        } else if (remoteLoginRequest.tag === "error" || remoteLoginRequest.tag === "not-found") {
            return "Error fetching data from backend.";
        } else {
            if (hasExpired) {
                window.location.reload();
            } else {
                return (
                    <div className={styles.qrView}>
                        <Center>
                            <p>
                                <Trans>Scan the QR code with a device where you already are logged in to Livlig.</Trans>
                            </p>

                            <img
                                className={styles.qrImage}
                                src={`${getBackendURL()}/remoteLogin/${remoteLoginRequest.value.sessionId}/image`}
                                alt={t`QR code for logging in remotely.`}
                            />
                            <div className={styles.code}>
                                <Center>{remoteLoginRequest.value.validateText}</Center>
                            </div>
                        </Center>
                    </div>
                );
            }
        }
    }

    return (
        <I18nProvider i18n={i18n}>
            <div className={styles.page}>
                <Center>
                    <h1 className={styles.qrTitle}>
                        <Trans>Remote login</Trans>
                    </h1>
                    {renderContent()}
                </Center>
            </div>
        </I18nProvider>
    );
}

export function RemoteLoginValidate() {
    const { t } = useLingui();
    const locationsApi = useLocationsAPI();
    const metadataApi = useMetadataAPI();
    const userApi = useUserAPI();

    const user = useLogin();
    const countries = unlessSuccessful(useApiCall(metadataApi.countries), null);

    const { sessionId } = useParams<{ sessionId: string }>();
    const [validateText] = useQueryParam("validateText", StringParam);

    const userLocationReq = useApiCall(userApi.userLocationDetails);
    const initialLocation =
        userLocationReq.tag === "success" ? userLocationReq.value.stored || userLocationReq.value.guessed : null;
    const [location, setLocation] = useState<LocationScopeWithMetadata | null>(initialLocation);

    const lang = extractSelectedLanguage();
    const [locale, setLocale] = useState<string | undefined>(lang);

    const remoteSessionReq = useApiCall(userApi.remoteLoginFetch, sessionId ?? "");

    function contentFn() {
        if (remoteSessionReq.tag === "loading") {
            return (
                <Segment variant={"secondary"} padded className={styles.topSegment}>
                    <LoadingIndicator />
                </Segment>
            );
        } else if (remoteSessionReq.tag === "success") {
            if (remoteSessionReq.value === "expired") {
                return (
                    <Segment variant={"secondary"} padded className={styles.topSegment}>
                        <Center>
                            <Trans>The remote login session has expired. </Trans>
                        </Center>
                    </Segment>
                );
            } else if (remoteSessionReq.value === undefined) {
                return (
                    <Segment variant={"secondary"} padded className={styles.topSegment}>
                        <Center>
                            <Trans>Could not find any remote login session.</Trans>
                        </Center>
                    </Segment>
                );
            } else {
                const validRequest = remoteSessionReq;

                async function validateRemoteLogin() {
                    if (
                        location &&
                        locale &&
                        (location.scope === "SellingUnit" ||
                            location.scope === "Country" ||
                            location.scope === "Global")
                    ) {
                        const itemSalesUrl: string = salesUrl({
                            type: location.scope,
                            id:
                                location.scope === "SellingUnit"
                                    ? location.sellingUnitCode
                                    : location.scope === "Country"
                                      ? location.countryCode
                                      : "",
                        });
                        await userApi.remoteLoginValidate(sessionId ?? "", itemSalesUrl + "/public-display", locale);
                        validRequest.refresh();
                        window.location.href = itemSalesUrl;
                    }
                }

                if (validRequest.value === "expired") {
                    return (
                        <Segment variant={"secondary"} padded className={styles.topSegment}>
                            <Center>
                                <Trans>The remote login session has expired.</Trans>
                            </Center>
                        </Segment>
                    );
                } else if (validRequest.value === undefined) {
                    return (
                        <Segment variant={"secondary"} padded className={styles.topSegment}>
                            <Center>
                                <Trans>Could not find any login session with this id.</Trans>
                            </Center>
                        </Segment>
                    );
                } else {
                    return (
                        <>
                            <Segment variant={"secondary"} padded className={styles.topSegment}>
                                <Center>
                                    <h1 className={styles.loginTitle}>
                                        <Trans>Remote login</Trans>
                                    </h1>
                                    <Trans>
                                        <strong>Username: {user.user?.getDisplayName()}</strong>
                                    </Trans>
                                </Center>
                                <hr className={styles.divider} />
                                <div>
                                    <Center>
                                        <p className={styles.verificationCode}>
                                            <strong>{validateText}</strong>
                                        </p>
                                        <p>
                                            <Trans>
                                                <strong>
                                                    Make sure this code matches the one on the other screen.
                                                </strong>
                                            </Trans>
                                        </p>
                                    </Center>
                                </div>
                            </Segment>
                            <Segment variant={"primary"} className={styles.bottomSegment} padded>
                                <h3 className={styles.bottomSegmentText}>
                                    <Trans>Choose the business unit you want to display</Trans>
                                </h3>
                                <Center>
                                    {userLocationReq.tag === "success" && countries !== null ? (
                                        <LocationSelector
                                            initialLocation={initialLocation}
                                            initialLocale={lang}
                                            countries={countries.countries}
                                            onLocationChange={(selection) => {
                                                selection && locationsApi.getMetadata(selection).then(setLocation);
                                            }}
                                            onLanguageChange={(selection) => {
                                                selection && setLocale(selection);
                                            }}
                                        />
                                    ) : (
                                        <LoadingIndicator />
                                    )}

                                    <Button
                                        className={styles.selectButton}
                                        onClick={validateRemoteLogin}
                                        type={"emphasised"}
                                    >
                                        <Trans>Select</Trans>
                                    </Button>
                                </Center>
                            </Segment>
                        </>
                    );
                }
            }
        } else if (remoteSessionReq.tag === "not-found") {
            return (
                <Segment variant={"secondary"} padded className={styles.topSegment}>
                    <Trans>Could not find any remote session request. Perhaps it has expired?</Trans>
                </Segment>
            );
        } else {
            return (
                <Segment variant={"secondary"} padded className={styles.topSegment}>
                    <Trans>There was an error getting remote session login data.</Trans>
                </Segment>
            );
        }
    }

    return <Page title={t`Remote login validate`}>{contentFn()}</Page>;
}
