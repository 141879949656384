import { Observable, Subject } from "rxjs";
import { useEffect, useMemo, useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import {
    AppUser,
    fetchWithUserCredentials,
    GeoPosition,
    getBackendURL,
    LocationScope,
    LocationScopeWithMetadata,
    useLogin,
} from "@ingka-livlig/frontend-lib";

const hostname = getBackendURL();

export type MapEvent = {
    timestamp: number;
    lat: number;
    lng: number;
    weight: number;
    totalAmount: number;
    inScope: boolean;
};

type SalesEvent = {
    countryCode?: string;
    sellingUnitCode: string;
    location: GeoPosition;
    lineItemCount: number;
    totalQuantity: number;
    totalAmount: number;
};

type MinuteSales = {
    startTime: string;
    events: SalesEvent[][];
    currencyCode: string;
};

export type SalesStats = {
    salesCount: number;
    totalQuantity: number;
    totalAmount: number;
};

export interface SalesAPI {
    salesLastMinute(currencyCode: string, lagMinutes: number): Promise<MinuteSales>;
}

function salesAPI(user: AppUser): SalesAPI {
    return {
        salesLastMinute: async (currencyCode: string, lagMinutes: number) => {
            const result = await fetchWithUserCredentials(
                user,
                `${hostname}/api/v1/sales/1m?currencyCode=${currencyCode}&lagMinutes=${lagMinutes}`,
            );
            return await result.json();
        },
    };
}

export function useSalesAPI() {
    const { user } = useLogin();
    return useMemo(() => salesAPI(user), [user]);
}

export function useSalesStreams(
    location?: LocationScopeWithMetadata,
    currencyLocation?: LocationScopeWithMetadata,
): [Observable<MapEvent>, Observable<SalesStats>, Observable<SalesStats>] {
    const api = useSalesAPI();

    const [eventSubject] = useState(new Subject<MapEvent>());
    const [minuteStatsSubject] = useState(new Subject<SalesStats>());
    const [secondStatsSubject] = useState(new Subject<SalesStats>());
    const [lag] = useQueryParam("lagMinutes", NumberParam);

    useEffect(() => {
        if (location) {
            const cancel = emitFromAPI(
                api,
                location,
                currencyLocation?.currency.code || location.currency.code,
                lag || 5,
                eventSubject,
                minuteStatsSubject,
                secondStatsSubject,
            );
            return () => {
                cancel();
            };
        }
        // We don't want to depend on changes on Rx Subjects so ignore warning that we don't
        // eslint-disable-next-line
    }, [location && JSON.stringify(location)]);

    return [eventSubject, minuteStatsSubject, secondStatsSubject];
}

async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function weight(scannedItemCount: number): number {
    if (scannedItemCount < 5) return 1;
    else if (scannedItemCount < 10) return 2;
    else return 3;
}

function calcStats(events: SalesEvent[]) {
    return events.reduce(
        (sum, curr) => {
            return {
                salesCount: sum.salesCount + 1,
                totalQuantity: sum.totalQuantity + curr.totalQuantity,
                totalAmount: sum.totalAmount + curr.totalAmount,
            };
        },
        {
            salesCount: 0,
            totalQuantity: 0,
            totalAmount: 0,
        } as SalesStats,
    );
}

/**
 * Fetch a minute worth of sales data with call to backend API and trickle out the filtered results
 * on provided Rx subjects and then fetch next minute and do the same again.
 * This async functions uses blocking sleep and looping internally and will stop when target Rx subject is marked stopped
 *
 * @param salesApi RST API access interface to fetch data from
 * @param location Mark sales data from this location as selected and calculate only stats on these
 * @param currencyCode The currency code to use
 * @param lagMinutes How many minutes earlier to fetch data from
 * @param eventsSubject Rx subject to emit sales events to
 * @param minuteStats Rx subject to emit SalesStats to
 * @param secondStats Rx subject to emit SalesStats to
 */

function emitFromAPI(
    salesApi: SalesAPI,
    location: LocationScope,
    currencyCode: string,
    lagMinutes: number,
    eventsSubject: Subject<MapEvent>,
    minuteStats: Subject<SalesStats>,
    secondStats: Subject<SalesStats>,
) {
    let matchLocation: (event: SalesEvent) => boolean;
    switch (location.scope) {
        case "Global":
            matchLocation = () => true;
            break;
        case "Country":
            matchLocation = (event) => location.countryCode === event.countryCode;
            break;
        case "SellingUnit":
            matchLocation = (event) => location.sellingUnitCode === event.sellingUnitCode;
            break;
    }

    /**
     * Don't show any Russian data.
     * @param event
     */
    const removeRussia = (event: SalesEvent) => event.countryCode !== "RU";

    let retryBackoff = 1;
    let finished = false;
    const cancel = () => {
        finished = true;
    };

    async function loop() {
        while (!finished) {
            let minuteData = null;
            try {
                minuteData = await salesApi.salesLastMinute(currencyCode, lagMinutes);
            } catch (e) {
                let sec = 1 + Math.floor(Math.random() * retryBackoff);
                console.warn("Failed to fetch data from API", e);
                await sleep(sec * 1000);
                if (retryBackoff < 16) {
                    retryBackoff += retryBackoff;
                }
                continue;
            }

            // Calculate summary stats for all events this minute
            minuteStats.next(
                calcStats(
                    minuteData.events
                        .flatMap((sec) => sec)
                        .filter(matchLocation)
                        .filter(removeRussia),
                ),
            );

            retryBackoff = 1;
            // Loop through the seconds in current minute
            for (let sec = 0; sec < 60; sec++) {
                const secondEvents = minuteData.events[sec].filter(removeRussia);
                secondStats.next(calcStats(secondEvents.filter(matchLocation)));

                if (secondEvents.length > 0) {
                    let timestamp = Date.now();
                    // Spread out emit of multiple events evenly in time within the second
                    const delay = 1000 / secondEvents.length;
                    for (let event of secondEvents) {
                        eventsSubject.next({
                            timestamp: timestamp,
                            lat: event.location.lat,
                            // Move the events a bit for them to line up with the 2D map
                            lng: event.location.lng - 12,
                            weight: weight(event.lineItemCount),
                            totalAmount: event.totalAmount,
                            inScope: matchLocation(event),
                        });
                        await sleep(delay);
                        timestamp += delay;
                    }
                } else {
                    await sleep(1000);
                }
                // Break seconds looping earlier if marked finished
                if (finished) {
                    break;
                }
            }
        }
    }

    loop().then();
    return cancel;
}
